import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { fixAssetUrl } from '@features/contentful/helpers/assetHelpers';
import { ArticleAdapter } from '@features/seo/adapters/article.adapter';
import {
    BreadCrumbItem,
    BreadcrumbListAdapter,
} from '@features/seo/adapters/breadcrumb-list.adapter';
import { ProductAdapter } from '@features/seo/adapters/product.adapter';
import { ReviewAdapter } from '@features/seo/adapters/review.adapter';
import { YoutubeVideoAdapter } from '@features/seo/adapters/youtube-video.adapter';
import {
    BasePage,
    Itinerary,
    KnowledgeArticle,
    Review,
    Trip,
    YoutubeVideo,
} from '@generated/graphql';
import { DjangoFactory } from '@models/django-factory';
import { SeoMetadata } from '@models/seo-metadata.model';
import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
    AboutPage,
    Article,
    BreadcrumbList,
    Product as SchemaProduct,
    Review as SchemaReview,
    Thing,
    VideoObject,
    WithContext,
} from 'schema-dts';
import { DjangoService } from './django.service';
import { EnvironmentService } from './environment.service';
import { MetadataService } from './metadata.service';

@Injectable({
    providedIn: 'root',
})
export class SeoService implements OnDestroy {
    public set seoMetadata(metadata: SeoMetadata) {
        this._seoMetadata.next(metadata);
    }
    public get seoMetadata$(): Observable<SeoMetadata> {
        return this._seoMetadata.asObservable();
    }

    private _seoMetadata = new ReplaySubject<SeoMetadata>(1);

    constructor(
        private router: Router,
        private djangoService: DjangoService,
        private metadataService: MetadataService,
        private environmentService: EnvironmentService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnDestroy(): void {
        this._seoMetadata.complete();
    }

    prefetchMetadata(route: string) {
        return this.djangoService.seoMetadata(route);
    }

    generateReview(review: Review): WithContext<SchemaReview> {
        const schema = new ReviewAdapter().adapt(review);
        return SeoService.addContext(schema);
    }

    generateProduct(trip: Trip): WithContext<SchemaProduct> {
        const schema = new ProductAdapter().adapt(trip);
        return SeoService.addContext(schema);
    }

    generateProductFromItinerary(
        itinerary: Itinerary
    ): WithContext<SchemaProduct> {
        const schema = new ProductAdapter().adaptFromItinerary(itinerary);
        return SeoService.addContext(schema);
    }

    generateArticle(article: KnowledgeArticle): WithContext<Article> {
        const schema = new ArticleAdapter().adapt(article);
        return SeoService.addContext(schema);
    }

    generateBreadCrumbs(items: BreadCrumbItem[]): WithContext<BreadcrumbList> {
        const schema = new BreadcrumbListAdapter().adapt(items);
        return SeoService.addContext(schema);
    }

    generateYoutubeVideo(video: YoutubeVideo): WithContext<VideoObject> {
        const schema = new YoutubeVideoAdapter().adapt(video);
        return SeoService.addContext(schema);
    }

    generateAboutUs(schema: AboutPage): WithContext<AboutPage> {
        return SeoService.addContext(schema);
    }

    setCanonicalUrl(route: string) {
        let cleanURL = this.router.parseUrl(route);
        cleanURL.queryParams = {};
        const linkElement = this.document.getElementById('canonicalUrl');
        const url = `${this.environmentService.hostName()}${cleanURL.toString()}`;
        linkElement.setAttribute('href', url);
        this.metadataService.setAttribute({ property: 'og:url', content: url });
    }

    handleCustomPageMetaTags(pageData: BasePage) {
        if (pageData.seoTitle) {
            this.metadataService.setTitle(pageData.seoTitle);
        } else {
            this.metadataService.setTitle(pageData.title);
        }

        let defaultMetaTags = [
            {
                name: 'description',
                content: pageData.seoDescription,
            },
            {
                property: 'og:description',
                content: pageData.seoDescription,
            },
            {
                property: 'og:type',
                content: 'website',
            },
        ];

        if (pageData?.featuredImage?.url) {
            defaultMetaTags = [
                ...defaultMetaTags,
                {
                    property: 'og:image',
                    content: `${fixAssetUrl(
                        pageData.featuredImage?.url
                    )}?w=1200&h=800&f=center&fit=fill&fm=jpg`,
                },
                {
                    property: 'og:image:secure_url',
                    content: `${fixAssetUrl(
                        pageData.featuredImage?.url
                    )}?w=1200&h=800&f=center&fit=fill&fm=jpg`,
                },
                {
                    property: 'og:image:type',
                    content: 'image/jpeg',
                },
                {
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    property: 'og:image:height',
                    content: '800',
                },
            ];
        }

        // Adding Meta Tags from contentful Data
        defaultMetaTags.forEach(metaObj => {
            this.metadataService.setAttribute(metaObj);
        });
        this.setCanonicalUrl(this.router.url);
    }

    private static addContext<T extends Thing>(schema: T): WithContext<T> {
        return {
            '@context': 'https://schema.org',
            // https://github.com/microsoft/TypeScript/issues/49982
            // @ts-ignore
            ...schema,
        };
    }
}

@Injectable({ providedIn: 'root' })
export class SeoResolver {
    constructor(
        private router: Router,
        private djangoService: DjangoService,
        private metadataService: MetadataService,
        private seoService: SeoService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return of(state.url).pipe(
            map((url: string) => this.router.parseUrl(url)),
            tap(urlTree => (urlTree.queryParams = {})),
            map(urlTree => urlTree.toString()),
            tap(route => this.seoService.setCanonicalUrl(route)),
            switchMap(route => this.djangoService.seoMetadata(route)),
            map(seoMetadata => DjangoFactory.build(SeoMetadata, seoMetadata)),
            tap((seoMetadata: SeoMetadata) => {
                if (seoMetadata.title) {
                    this.metadataService.setTitle(seoMetadata.title);
                }
                for (let metaTag of seoMetadata.metaTags) {
                    this.metadataService.setAttribute(metaTag.metaDefinition);
                }
            }),
            tap(data => (this.seoService.seoMetadata = data)),
            catchError(() => of(null))
        );
    }
}
